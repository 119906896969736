const { $ } = window;

/**
 * 从浏览路径中解析指定参数
 *
 * @param {string} key 参数名
 * @param {string} url 浏览路径
 */
export const getQueryStr = (key, url) => {
  if (!url || !url.length) {
    url = window.location.href;
  }
  const res = new RegExp(`(?:&|/?)${key}=([^&$]+)`).exec(url);
  return res ? res[1] : '';
};

const nunjucks = require('nunjucks');

export const songbaiSimilar = {
  /**
   * 获取相关推荐列表
   *
   * @param {number|string} id 问答/话题/语音 id
   * @param {number|string} type 相关推荐类型
   * @param {Function} cb 获取相关推荐列表成功后回调
   */
  getSimilarList(id, type, cb) {
    $.ajax({
      url: '/pcs/similar_list/',
      type: 'GET',
      data: {
        id,
      },
      success(res) {
        let similarList = [];
        if (res && res.topic_content_list && res.topic_content_list.length) {
          similarList = res.topic_content_list;
        }
        cb(similarList, type);
      },
    });
  },
  /**
   * 渲染相关推荐至页面指定区域
   *
   * @param {number|string} type 相关推荐类型
   * @param {array} similarList 相关推荐数据列表
   */
  renderSimilarList(similarList, type) {
    if (!similarList || !similarList.length) {
      return;
    }
    // eslint-disable-next-line global-require
    const doctorListTpl = require('@/components/doctor-list/doctor-list.html').default;
    const similarListTpl = `<div class="similar-section"><div class="section-header">相关推荐</div>${doctorListTpl}</div>`;
    const renderList = {
      list: similarList,
      list_type: type,
    };
    const similarListHtml = nunjucks.renderString(similarListTpl, renderList);
    $('.similar-wrap').html(similarListHtml);
  },
  /**
   * 初始化相关推荐
   */
  init() {
    const similarType = $('.songbai-main').data('type');
    const id = $('.songbai-main').data('id');
    // 获取相关推荐列表并渲染至页面指定位置
    this.getSimilarList(id, similarType, this.renderSimilarList.bind(this));
  },
};

/**
 * 打点
 */
export const doPoint = {
  basePoint(domain, key, segmentation, cb) {
    if (segmentation && typeof segmentation === 'object') {
      segmentation.app = '松柏pc站';
    } else {
      segmentation = {
        app: '松柏pc站',
      };
    }

    $.ajax({
      url: `${domain}/stat/h5/event_analyse/data_upload/`,
      type: 'POST',
      data: {
        events: JSON.stringify([
          {
            key,
            segmentation,
          },
        ]),
      },
      complete() {
        if (cb && typeof cb === 'function') {
          cb();
        }
      },
    });
  },
  appClick(domain, segmentation, cb) {
    this.basePoint(domain, 'AppClick', segmentation, cb);
  },
};
