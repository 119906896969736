import { doPoint } from '@assets/utils';

const { $ } = window;
$('.link-btn').click(() => {
  const position = $('.link-btn').data('position');
  const domain = $('.link-btn').data('domain');

  let pointPosition = '';
  switch (position) {
    case 'home':
      pointPosition = '松柏pc站首页';
      break;
    case 'disease-list':
      pointPosition = '疾病词列表';
      break;
    case 'qa-audio-detail':
      pointPosition = '语音话题详情页';
      break;
    case 'qa-audio-list':
      pointPosition = '语音话题列表';
      break;
    case 'qa-multiple-detail':
      pointPosition = '医患问答详情页';
      break;
    case 'qa-multiple-list':
      pointPosition = '医患问答列表';
      break;
    case 'topic-list':
      pointPosition = '文字话题列表';
      break;
    case 'topic-detail':
      pointPosition = '文字话题详情页';
      break;
    default:
      pointPosition = '';
  }

  doPoint.appClick(domain, {
    click_position: '去提问',
    page_readable_name: pointPosition,
  }, () => {
    window.location.href = `${domain}/pc/wx_qr_page/?from_type=seo-pc&order_type=quick_ask`;
  });
});
